import React from 'react'
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'isomorphic-fetch'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: 'https://us-central1-pedro-staging.cloudfunctions.net/graphql',
    // uri: "http://localhost:5000/pedro-staging/us-central1/graphql",
    fetch
  })
})

// eslint-disable-next-line react/prop-types,react/display-name
export default ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)