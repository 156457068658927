// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app'
import React from 'react'
import config from './firebase-cred.json'
// Add the Firebase services that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

class Firebase {
  constructor() {
    if (typeof window !== 'undefined') {
      const app = firebase
      app.initializeApp(config)
      this.auth = firebase.auth()
      this.db = firebase.firestore()
      this.storage = firebase.storage()
      this.uiConfig = {
        signInFlow: 'popup',
        signInOptions: [app.auth.GoogleAuthProvider.PROVIDER_ID],
      }
      this.arrayUnion = firebase.firestore.FieldValue.arrayUnion
      if (process.env.GATSBY_ENV === 'local') {
        app.functions().useEmulator('localhost', 5001)
        this.db.useEmulator('localhost', 8080)
        this.storage.useEmulator('localhost', 9199)
        this.auth.useEmulator('http://localhost:9099')
      }
    }
  }
}

export default new Firebase()

// Creating and exporting firebase context
const FirebaseContext = React.createContext(null)
const LocalSession =
  typeof window !== 'undefined' ? firebase.auth.Auth.Persistence.LOCAL : false
export { FirebaseContext, LocalSession }
